import React, { useEffect, useState } from "react";
import styles from "./footer.module.scss";
import { Container, Grid, Typography, List, ListItem } from "@mui/material";
import { Box } from "@mui/system";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GeneralService } from "../../services";
import { Margin } from "@mui/icons-material";

function Footer() {
  const { t, i18n } = useTranslation();
  const [answerPage, setanswerPage] = useState([]);

  useEffect(() => {
    GeneralService.getAll("/GetAll", {
      Type: 8,
      Language: i18n.language === "tr" ? 1 : 0,
    })
      .then((data) => {
        const datalist = data.DataList;
        setanswerPage(datalist);
      })
      .catch((err) => {
        console.log("Error", err);
        throw err;
      });
  }, [i18n.language]);

  const [language, setLanguage] = useState(i18n.language);
  // Dil değişikliği yapıldığında bileşeni yeniden render et
  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);
  return (
    <>
      <Grid columnSpacing={{ md: 12 }} className={styles.footerHome}>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className={styles.bottom}
            >
              <Grid xs={2} sm={4} md={3} className={styles.contactList}>
                <Box className={styles.title}>
                  <Typography variant="h3">
                    {t("contactDetail.turkiye.title")}
                  </Typography>
                  <Typography>
                    {t("contactDetail.turkiye.generalCenter")}
                  </Typography>
                </Box>
                <List>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <EmailIcon></EmailIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.email")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              info@dgpays.com
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <LocationOnIcon></LocationOnIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.adres")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              Maslak Mah. Eski Büyükdere Cad. Koç Kaya As Plaza
                              No:1 Kat:16 Sarıyer – İstanbul
                            </Typography>
                          </Grid>
                          <Grid
                            className={styles.contentText2}
                            borderTop={1}
                            borderColor={"#FFFFFF"}
                            paddingTop={1}
                            marginTop={1}
                          >
                            <Typography variant="span">
                              DgpaysIT Digitalpark Teknopark Barbaros, Şebboy
                              Sk. No:4/1 İç Kapı No:1, 34758 Ataşehir - İstanbul
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <PhoneIcon></PhoneIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.telefon")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              +90 212 290 40 10
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={2} sm={4} md={3} className={styles.contactList}>
                <Box className={styles.title}>
                  <Typography variant="h3">
                    {t("contactDetail.dubai")}
                  </Typography>
                  <Typography> Dgpays Middle East and Africa </Typography>
                </Box>
                <List>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <EmailIcon></EmailIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.email")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              intsales@dgpays.com
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <LocationOnIcon></LocationOnIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.adres")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              Office 2601, East Tower, Index Tower, DIFC, POBox
                              482018, Dubai, United Arab Emirates
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <PhoneIcon></PhoneIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.telefon")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              +971 4 4392600
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={2} sm={4} md={3} className={styles.contactList}>
                <Box className={styles.title}>
                  <Typography variant="h3">
                    {t("contactDetail.luksemburg")}
                  </Typography>
                  <Typography>
                    Dgpays Société à Responsabilité Limitée
                  </Typography>
                </Box>
                <List>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <EmailIcon></EmailIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.email")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              intsales@dgpays.com
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <LocationOnIcon></LocationOnIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.adres")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              4 rue Jean Pierre Probst 2352 Luxemburg
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={2} sm={4} md={3} className={styles.contactList}>
                <Box className={styles.title}>
                  <Typography variant="h3">{t("contactDetail.abd")}</Typography>
                  <Typography> Dgpays North America, LLC </Typography>
                </Box>
                <List>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <EmailIcon></EmailIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.email")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              intsales@dgpays.com
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={2}>
                      <Grid>
                        <LocationOnIcon></LocationOnIcon>
                      </Grid>
                      <Grid
                        xs={12}
                        sm
                        container
                        className={styles.footerContent}
                      >
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Typography variant="h4">
                              {t("contactDetail.adres")}
                            </Typography>
                          </Grid>
                          <Grid className={styles.contentText}>
                            <Typography variant="span">
                              1209 Orange Street Wilmington 19801, DE
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Grid className={styles.copyright}>
          <ul>
            {answerPage.map((item) => {
              const textData = JSON.parse(item.Text);
              return textData.FooterStatus == true ? (
                <li key={item.Id}>
                  <Link to={t("routes.standartPage") + "/" + textData.SeoUrl}>
                    {textData.Title}
                  </Link>
                </li>
              ) : null;
            })}
          </ul>
        </Grid>
        <Grid className={styles.copyright}>
          <Typography variant="p">{t("copyright")}</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
